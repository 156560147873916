/// <reference path="../../../TSDef/p5.global-mode.d.ts" />

let gridCols, gridRows, gridResolution;
let grid;
let noiseX, noiseXInc, noiseY, noiseYInc, noiseZ, noiseZInc;

function setup(){
    let canvas = createCanvas(windowWidth, windowHeight);
    canvas.parent('sketch-holder');
    frameRate(30);

    makeGrid();

    noiseX = 0;
    noiseY = 0;
    noiseZ = 0;
    noiseXInc = 0.06;
    noiseYInc = 0.06;
    noiseZInc = 0.0071;
}
function draw(){
    background(0, 200, 100, 100);
    noStroke();
    fill(0, 230);

    generateNoiseField();

    for (let y = 0; y < gridRows-1; y++) {
        for (let x = 0; x < gridCols-1; x++) {
            let score = 0;
            let pt1 = grid[y][x];
            let pt2 = grid[y][x + 1];
            let pt3 = grid[y + 1][x + 1];
            let pt4 = grid[y + 1][x];

            if (pt1 == 1) {
                score += 8;
            }
            if (pt2 == 1) {
                score += 4;
            }
            if (pt3 == 1) {
                score += 2;
            }
            if (pt4 == 1) {
                score += 1;
            }

            push();
            translate(x * gridResolution, y * gridResolution);
            drawShape(score);
            pop();

        }
    }
}
function windowResized() {
    resizeCanvas(windowWidth, windowHeight);
    makeGrid();
}

function generateNoiseField() {
    noiseY = 0;
    for (let y = 0; y < gridRows; y++) {
        noiseX = 0;
        noiseY += noiseYInc;
        for (let x = 0; x < gridCols; x++) {
            noiseX += noiseXInc;
            grid[y][x] = round(noise(noiseX, noiseY, noiseZ));
        }
    }
    noiseZ += noiseZInc;
}

function makeGrid(){
    //background(0, 200, 100);
    gridResolution = 100;
    gridCols = ceil(width / gridResolution) + 1;
    gridRows = ceil(height / gridResolution) + 1;

    grid = [];
    for (let i = 0; i < gridCols; i++) {
        grid[i] = [];
    }
}

function drawShape(num){
        // FILLS
        switch (num) {
            case 0:

                break;
            case 1:
                beginShape();
                vertex(0, 50);
                vertex(50, 100);
                vertex(0, 100);
                endShape(CLOSE);
                break;
            case 2:
                beginShape();
                vertex(100, 50);
                vertex(100, 100);
                vertex(50, 100);
                endShape(CLOSE);
                break;
            case 3:
                beginShape();
                vertex(0, 50);
                vertex(100, 50);
                vertex(100, 100);
                vertex(0, 100);
                endShape(CLOSE);
                break;
            case 4:
                beginShape();
                vertex(50, 0);
                vertex(100, 0);
                vertex(100, 50);
                endShape(CLOSE);
                break;
            case 5:
                beginShape();
                vertex(50, 0);
                vertex(100, 0);
                vertex(100, 50);
                vertex(50, 100);
                vertex(0, 100);
                vertex(0, 50);
                endShape(CLOSE);
                break;
            case 6:
                beginShape();
                vertex(50, 0);
                vertex(100, 0);
                vertex(100, 100);
                vertex(50, 100);
                endShape(CLOSE);
                break;
            case 7:
                beginShape();
                vertex(50, 0);
                vertex(100, 0);
                vertex(100, 100);
                vertex(0, 100);
                vertex(0, 50);
                endShape(CLOSE);
                break;
            case 8:
                beginShape();
                vertex(0, 0);
                vertex(50, 0);
                vertex(0, 50);
                endShape(CLOSE);
                break;
            case 9:
                beginShape();
                vertex(0, 0);
                vertex(50, 0);
                vertex(50, 100);
                vertex(0, 100);
                endShape(CLOSE);
                break;
            case 10:
                beginShape();
                vertex(0, 0);
                vertex(50, 0);
                vertex(100, 50);
                vertex(100, 100);
                vertex(50, 100);
                vertex(0, 50);
                endShape(CLOSE);
                break;
            case 11:
                beginShape();
                vertex(0, 0);
                vertex(50, 0);
                vertex(100, 50);
                vertex(100, 100);
                vertex(0, 100);
                endShape(CLOSE);
                break;
            case 12:
                beginShape();
                vertex(0, 0);
                vertex(100, 0);
                vertex(100, 50);
                vertex(0, 50);
                endShape(CLOSE);
                break;
            case 13:
                beginShape();
                vertex(0, 0);
                vertex(100, 0);
                vertex(100, 50);
                vertex(50, 100);
                vertex(0, 100);
                endShape(CLOSE);
                break;
            case 14:
                beginShape();
                vertex(0, 0);
                vertex(100, 0);
                vertex(100, 100);
                vertex(50, 100);
                vertex(0, 50);
                endShape(CLOSE);
                break;
            case 15:
                beginShape();
                vertex(0, 0);
                vertex(100, 0);
                vertex(100, 100);
                vertex(0, 100);
                endShape(CLOSE);
                break;
        }
}